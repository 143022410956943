export default defineNuxtRouteMiddleware((to, from) => {
  if (!process.server) {
    const isOverviewPage =
      /^dashboard-overview(-|$)/.test(to.name) &&
      /^dashboard-overview(-|$)/.test(from.name);

    if (from.name !== to.name && isOverviewPage) {
      to.meta.pageTransition = false;
    }
  }
});
